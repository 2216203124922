import {
  SuperRareBazaarAbi,
  getSuperRareBazaar,
} from "@/contracts/superrarebazaar"
import { useTransactionModalStore } from "@/web/stores/transactionModal"
import { genToastId } from "@/utils/toast"
import { TxToastError } from "@/utils/txUtils"
import toast from "react-hot-toast"
import { zeroAddress } from "viem"
import { useAccount, useWriteContract } from "wagmi"
import { getSuperRareV1, SuperRareV1Abi } from "@/contracts/superrarev1"
import { addressesAreEqual } from "@/utils/addressesAreEqual"

export const useRemoveOffer = ({
  currencyOffer,
  tokenContract,
  tokenId,
}: {
  tokenContract: `0x${string}`
  tokenId: number
  currencyOffer?: `0x${string}`
}): {
  removeOffer: (onSubmit?: () => void) => void
  isLoading: boolean
} => {
  const { chain } = useAccount()
  const bazaarContract = chain?.id ? getSuperRareBazaar(chain?.id) : null
  const superrareV1 = chain?.id ? getSuperRareV1(chain?.id) : null
  const { setHash, setToastId } = useTransactionModalStore()
  const {
    writeContractAsync,
    isPending,
    error: errorContract,
  } = useWriteContract()
  const removeOffer = async (onSubmit?: () => void): Promise<void> => {
    const randomId = genToastId()

    if (!writeContractAsync) {
      toast.error("Error: writeContractAsync " + errorContract)
      return
    }
    try {
      toast.loading("Awaiting user confirmation...", { id: randomId })
      if (!bazaarContract) {
        toast.error("Error: bazaarContract or superrareV1 is null")
        return
      }

      const result = await (() => {
        if (superrareV1 && addressesAreEqual(tokenContract, superrareV1)) {
          return writeContractAsync({
            address: superrareV1,
            abi: SuperRareV1Abi,
            functionName: "cancelBid",
            chainId: chain?.id,
            args: [BigInt(tokenId)],
          })
        }
        return writeContractAsync({
          address: bazaarContract,
          abi: SuperRareBazaarAbi,
          functionName: "cancelOffer",
          chainId: chain?.id,
          args: [tokenContract, BigInt(tokenId), currencyOffer || zeroAddress],
        })
      })()
      result && (setHash(result), setToastId(randomId))
      result && toast.loading("Transaction sent", { id: randomId })
      onSubmit?.()
    } catch (error) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
    }
  }
  return {
    removeOffer,
    isLoading: isPending,
  }
}
